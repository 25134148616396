<template>
  <div class="ticket-done" v-if="!isProcessing">
    <div class="ticket-done__complete">
      <v-icon class="ticket-done__complete__icon">check_circle_outline</v-icon>
      <h2 class="ticket-done__complete__title">回数券のご利用完了</h2>
      <h3 class="ticket-done__complete__explain">この画面をスタッフに見せてください</h3>
      <dl class="ticket-done__complete__table">
        <dt class="ticket-done__complete__table__key">ご利用日時</dt>
        <dd class="ticket-done__complete__table__value">{{ usedAt }}</dd>
        <dt class="ticket-done__complete__table__key">回数券番号</dt>
        <dd class="ticket-done__complete__table__value">{{ ticketNumber }}</dd>
      </dl>
      <v-btn class="ticket-done__complete__btn" depressed @click="$router.push({ name: 'home' })">ホームへ移動する</v-btn>
    </div>
  </div>
</template>

<script>
import moment from '@/assets/lib/moment'

export default {
  name: 'ticket-done',
  mixins: [moment],
  async mounted () {
    // 最新の回数券の履歴情報がない場合は履歴全体を取得する
    if (!this.latestUsedTicket) await this.$store.dispatch('tickets/getUsedTicketHistories', this.uid)

    // それでもない場合は404へ
    if (!this.latestUsedTicket) {
      this.$router.push({ name: 'notfound' })
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} 最新の利用済み回数券
     */
    latestUsedTicket () {
      return this.$store.getters['tickets/latestUsedTicket']
    },
    /**
     * @return {String} 利用日時
     */
    usedAt () {
      const ticket = this.latestUsedTicket
      return ticket ? this.formatted(ticket.usedAt.toDate(), 'YYYY/MM/DD HH:mm') : null
    },
    /**
     * @return {String} 回数券番号
     */
    ticketNumber () {
      const ticket = this.latestUsedTicket
      return ticket ? ticket.tiid : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.ticket-done {
  display: flex;
  align-items: center;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  text-align: center;
  background-color: $green_color;
  &__complete {
    width: 100%;
    padding: $padding_height $padding_width;
    &__icon {
      font-size: 20rem;
      color: $white_color;

      @media (max-width: $max_width) {
        font-size: calc(100vw * 0.488);
      }
    }
    &__title {
      margin-top: 10px;
      font-size: 2rem;
      font-weight: bold;
      color: $white_color;
    }
    &__explain {
      margin: 10px 0 0;
      font-size: 1.4rem;
      font-weight: normal;
      color: $white_color;
    }
    &__table {
      box-sizing: border-box;
      width: 85%;
      margin: 40px auto 0;
      font-size: 1.6rem;
      color: $white_color;
      text-align: left;
      &__key {
        float: left;
        width: 100px;
        clear: both;
      }
      &__value {
        margin-left: 100px;
      }
    }
    &__btn {
      display: block;
      margin: 60px auto 0;
      font-size: 1.2rem;
      color: $green_color;
      border-radius: 15px;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 10px 20px;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $white_color;
      }
    }
  }
}
</style>
